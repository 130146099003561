.search-filters {
    min-height: 40px;
    box-sizing: content-box;
    padding: 25px 0;
    background-color: #ffffff;
    border-top: none !important;
    .btn-group {
        vertical-align: top;
        float: left;
    }
    .filters-item {
        width: 100%;
        height: 40px;
        float: left;
        border: none;
        font-weight: bold;
        font-size: 15px;
        text-align: center;
        &.filter {
            width: 315px;
            text-align: center;
            background: #d9d9d9;
        }
    }
}

.filters-keywords {
    background-color: #ffffff;
    padding-top: 20px;
    margin-bottom: -2px;
    .filter-keyword{
        font-weight: 600;
        text-decoration: underline;
        text-align: left;
        display: inline-block;
        margin-right: 13px;
        position: relative;
        padding-right: 27px;
        .filter-keyword-remove {
            position: absolute;
            text-decoration: none;
            top: -14px;
            right: -14px;
            &:before {
                font-size: 50px;
                color: #000000;
                display: inline-block;
            }
        }
    }
}

#filters-loader {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0,0,0, 0.75);
    text-align: center;
    &>.spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -15px;
        margin-left: -15px;
    }
}

.filters-modal {
    background: #ffffff;
    padding: 0 !important;



    .modal-dialog{
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        margin-top: 0 !important;
        .modal-content{
            border: none;
            box-shadow: none;
            border-radius: 0px;
            .modal-header{
                background-color: #000;
                padding: 0px 30px;
                border: none;
                .modal-title{
                    font-size: 15px;
                    font-weight: bold;
                    color: #fff;
                    height: 50px;
                    line-height: 50px;
                }
                .close{
                    margin-right: -15px;
                    font-size: 50px;
                    opacity: 1;
                    filter: Alpha(opacity=100); /* IE8 and earlier */
                    color: #fff;
                    text-shadow: none;
                }
            }
            .modal-body{
                color: #000;
                font-weight: 600;
            }
        }
    }

    .filters-list{
        padding-left: 30px;
        padding-right: 30px;

        &.root{
            padding: 0px;
        }

        .filter-checkbox > [type="checkbox"],
        .filter-checkbox > label{
            margin-bottom:0px !important;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            width: 100%;
        }
        .filter-checkbox > [type="checkbox"]:not(:checked),
        .filter-checkbox > [type="checkbox"]:checked {
            position: absolute;
            left: -9999px;
        }
        .filter-checkbox > [type="checkbox"]:not(:checked) + label,
        .filter-checkbox > [type="checkbox"]:checked + label {
            position: relative;
            padding-left: 32px;
            cursor: pointer;
            font-weight: 600;
            font-size: 15px;
        }
        .filter-checkbox > [type="checkbox"]:checked + label {
            font-weight: bold;
        }
        .filter-checkbox > [type="checkbox"]:not(:checked) + label:before,
        .filter-checkbox > [type="checkbox"]:checked + label:before {
            content: '';
            position: absolute;
            left:0;
            width: 22px;
            height: 22px;
            border: 1px solid #000;
            background: #fff;
            border-radius: 0px;
        }
        .filter-checkbox > [type="checkbox"]:not(:checked) + label:after,
        .filter-checkbox > [type="checkbox"]:checked + label:after {
            position: absolute;
            top: 50%;
            margin-top:-7px;
            left: 2px;
        }

        .filter-checkbox > [type="checkbox"]:checked + label:before {
            background: #000;
        }

        .filter-checkbox{
            padding: 5px 30px 5px 30px;
            margin: 0px;
            &.open{
                background-color: #f7f7f7;
            }
            .toggle-childs{
                float: right;
                font-size: 50px;
                position: absolute;
                right: -13px;
                top: -14px;
                .open-childs{
                    display: none;
                }
                .close-childs{
                    display: block;
                }
            }
            .collapsed{
                .open-childs{
                    display: block;
                }
                .close-childs{
                    display: none;
                }
            }
        }

        li{
            &.root{
                border-bottom: 1px solid #d9d9d9;
                &.last{
                    border: none;
                }
                > .filter-checkbox{
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
            &.first{
                > .filter-checkbox{
                    padding-top: 10px;
                }
            }
            &.last{
                > .filter-checkbox{
                    padding-bottom: 10px;
                }
            }
        }

    }

}