//General Specific to product list
@import '../../../partials/general';
@import '../../../partials/tablet/general';
@import '../../../partials/tablet/header';
@import '../../../partials/tablet/footer';
@import '../../../widgets/breadcrumbs/general';
@import '../../../widgets/social/general';
@import '../../../partials/general/actions';

@import 'general';

.product-list-view-more-btn{
    width: 330px;
    margin: 0 auto;
}

.store-product-list{
    .products-list-row{
        .product-sinopse,
        .product-price,
        .product-title{
            padding-top: 5px;
        }
        .product-title {
            font-size: 18px;
            line-height: 22px;
            text-transform: none;
        }
    }

    .products-list-components-wrapper{
        &.one-product-components-row{
            .one{
                .open-components-triangle{
                    left: 25.70%;
                }
            }
        }
        &.two-products-components-row{
            .one{
                .open-components-triangle{
                    left: 25.70%;
                }
            }
            .two{
                .open-components-triangle{
                    left: 78%;
                }
            }
        }
    }

    .product-info {
        .product-postage-time {
            font-weight: 400;
        }
        .product-price {
            .pvp-price {
                font-size: 25px;
            }
            .cover-price {
                padding-left: 10px;
            }
        }
    }

}
